<template>
  <div class="flex flex-col h-[100dvh] bg-gray-100">
    <!-- Bannière d'installation -->
    <div
      v-if="showInstallBanner"
      class="bg-primary-500 text-white px-4 py-3 flex items-center justify-between"
    >
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2m0 0H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Installez l'application pour recevoir les notifications</span>
      </div>
      <button
        @click="installPWA"
        class="ml-4 px-4 py-2 bg-white text-primary-500 rounded-md font-medium hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-white"
      >
        Installer
      </button>
    </div>

    <!-- Header -->
    <header class="bg-white shadow flex-none">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <h2 class="text-xl font-semibold text-gray-900">
          Bonjour {{ user.firstName }} 👋 !
        </h2>
        <div class="relative">
          <button
            @click="showMenu = !showMenu"
            class="flex items-center text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            <span class="sr-only">Menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
          <!-- Menu déroulant -->
          <div
            v-if="showMenu"
            class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <button
                @click="router.push('/settings')"
                class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                </svg>
                Paramètres
              </button>
              <button
                @click="handleLogout"
                class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                </svg>
                Se déconnecter
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Liste des maraudes -->
    <main class="flex-1 max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8">
      <!-- Loading state -->
      <div v-if="loading" class="text-center py-12">
        <svg class="animate-spin h-8 w-8 text-primary-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>

      <div v-else class="px-4 sm:px-0">
        <div v-if="Object.keys(maraudesByDay).length === 0" class="text-center py-12">
          <p class="text-gray-500">Aucune maraude disponible pour le moment.</p>
        </div>

        <div v-else>
          <div v-for="(day, dateKey) in maraudesByDay" :key="dateKey" class="mb-12">
            <h2 class="text-2xl font-bold text-gray-900 mb-4">{{ formatDate(day.displayDate) }}</h2>
            <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <div
                v-for="maraude in day.maraudes"
                :key="maraude.id"
                :class="[
                  'bg-white rounded-lg shadow overflow-hidden transform transition-all duration-200',
                  isParticipating(maraude.id) ? 'ring-2 ring-primary-500' : ''
                ]"
              >
                <!-- Image -->
                <div class="relative h-24 w-full bg-gray-200">
                  <img
                    src="https://www.leparisien.fr/resizer/0lUzYJC27L8bTqPd1Ja-Pf3PGcI=/932x582/cloudfront-eu-central-1.images.arcpublishing.com/leparisien/V2NB7O5R3LC566GWDCH4JG7OWY.jpg"
                    alt="Maraude"
                    class="h-full w-full object-cover"
                  >
                  <!-- Badge de participation -->
                  <div
                    v-if="isParticipating(maraude.id)"
                    class="absolute top-2 right-2 bg-primary-500 text-white px-2 py-1 rounded-full text-xs font-medium"
                  >
                    Inscrit
                  </div>
                </div>

                <div class="p-4">
                  <!-- En-tête avec titre et heure -->
                  <div class="flex justify-between items-start mb-3">
                    <h3 class="text-lg font-medium text-gray-900">
                      {{ maraude.title }}
                    </h3>
                    <div class="flex items-center text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                      </svg>
                      <span class="text-sm">{{ formatTime(maraude.date) }}</span>
                    </div>
                  </div>

                  <!-- Participants -->
                  <button
                    @click="openParticipantsModal(maraude)"
                    class="flex items-center mb-4 hover:text-primary-600 transition-colors duration-200"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    <span class="text-sm text-gray-600">{{ maraude.participants?.length || 0 }} participant(s)</span>
                  </button>

                  <!-- Bouton d'action -->
                  <button
                    v-if="!isParticipating(maraude.id)"
                    @click="participateInMaraude(maraude.id)"
                    :disabled="processingMaraude === maraude.id"
                    class="inline-flex items-center justify-center px-3 py-1.5 text-sm font-medium text-white bg-primary-500 rounded hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    <span v-if="processingMaraude === maraude.id" class="mr-2">
                      <svg class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    S'inscrire
                  </button>
                  <button
                    v-else
                    @click="cancelParticipation(maraude.id)"
                    :disabled="processingMaraude === maraude.id"
                    class="inline-flex items-center justify-center px-3 py-1.5 text-sm font-medium text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    <span v-if="processingMaraude === maraude.id" class="mr-2">
                      <svg class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    Se désinscrire
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Modal des participants -->
    <div
      v-if="selectedMaraude"
      class="fixed inset-0 z-50 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!-- Fond sombre -->
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          @click="selectedMaraude = null"
        ></div>

        <!-- Centre la modal -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!-- Contenu de la modal -->
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4" id="modal-title">
                  Participants à la maraude du {{ formatDate(selectedMaraude.date) }}
                </h3>
                <div class="mt-2">
                  <ul class="divide-y divide-gray-200">
                    <li
                      v-for="participant in selectedMaraude.participants"
                      :key="participant"
                      class="py-3 flex items-center justify-between"
                    >
                      <span class="text-sm text-gray-900">{{ participantNames[participant] || 'Chargement...' }}</span>
                      <span
                        v-if="isCurrentUser(participant)"
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800"
                      >
                        Vous
                      </span>
                    </li>
                    <li v-if="!selectedMaraude.participants?.length" class="py-3 text-sm text-gray-500">
                      Aucun participant pour le moment
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="selectedMaraude = null"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const formattedDate = format(date, 'EEE d MMM', { locale: fr })

  const response = formattedDate.toString()
  return response.charAt(0).toUpperCase() + response.slice(1)
}

const formatTime = (dateString) => {
  const date = new Date(dateString)
  return format(date, 'HH:mm')
}

export default {
  name: 'MaraudesView',
  
  setup() {
    const store = useStore()
    const router = useRouter()
    const showMenu = ref(false)
    const selectedMaraude = ref(null)
    const processingMaraude = ref(null)
    const participantNames = ref({}) // Pour stocker les noms des participants

    const user = computed(() => store.state.user)
    const maraudes = computed(() => store.state.maraudes)
    const loading = computed(() => store.state.loading)

    const showInstallBanner = ref(false)
    let deferredPrompt = null

    // Écouter l'événement beforeinstallprompt
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault()
      deferredPrompt = e
      showInstallBanner.value = true
    }

    // Fonction pour installer l'application
    const installPWA = async () => {
      if (!deferredPrompt) return

      try {
        // Afficher le prompt d'installation
        const result = await deferredPrompt.prompt()
        console.log('Résultat du prompt d\'installation:', result)
        
        // Réinitialiser le deferredPrompt
        deferredPrompt = null
        showInstallBanner.value = false

        // Vérifier si l'installation a réussi
        if (result.outcome === 'accepted') {
          console.log('Application installée avec succès')
          // Activer les notifications périodiques si nécessaire
          if (store.state.notificationsEnabled && 'periodicSync' in registration) {
            try {
              await registration.periodicSync.register('check-notifications', {
                minInterval: 60000
              })
            } catch (error) {
              console.error('Erreur lors de l\'activation des notifications périodiques:', error)
            }
          }
        }
      } catch (error) {
        console.error('Erreur lors de l\'installation:', error)
      }
    }

    const isCurrentUser = (participant) => {
      return participant === user.value
    }

    // Fonction pour charger les informations des participants
    const loadParticipantInfo = async (participantId) => {
      if (!participantNames.value[participantId]) {
        const userInfo = await store.dispatch('fetchUserInfo', participantId)
        participantNames.value[participantId] = userInfo.Nom
      }
      return participantNames.value[participantId]
    }

    // Fonction pour ouvrir la modal avec chargement des noms
    const openParticipantsModal = async (maraude) => {
      selectedMaraude.value = maraude
      // Charge les informations de tous les participants
      await Promise.all(maraude.participants.map(loadParticipantInfo))
    }

    const maraudesByDay = computed(() => {
      const grouped = {}
      store.state.maraudes.forEach(maraude => {
        const dateKey = maraude.date.split('T')[0]
        if (!grouped[dateKey]) {
          grouped[dateKey] = {
            displayDate: maraude.date,
            maraudes: []
          }
        }
        grouped[dateKey].maraudes.push(maraude)
      })
      
      return Object.fromEntries(
        Object.entries(grouped)
          .sort(([a], [b]) => new Date(a) - new Date(b))
      )
    })

    const isParticipating = (maraudeId) => {
      if (!user.value || !user.value.id) return false
      const maraude = maraudes.value.find(m => m.id === maraudeId)
      return maraude?.participants.includes(user.value.id)
    }

    const participateInMaraude = async (maraudeId) => {
      if (!user.value) return
      processingMaraude.value = maraudeId
      try {
        await store.dispatch('participateInMaraude', maraudeId)
      } catch (error) {
        // Afficher une notification d'erreur si nécessaire
      } finally {
        processingMaraude.value = null
      }
    }

    const cancelParticipation = async (maraudeId) => {
      if (!user.value) return
      processingMaraude.value = maraudeId
      try {
        await store.dispatch('cancelParticipation', maraudeId)
      } catch (error) {
        // Afficher une notification d'erreur si nécessaire
      } finally {
        processingMaraude.value = null
      }
    }

    const handleLogout = async () => {
      await store.dispatch('logout')
      router.push('/login')
    }

    const loadMaraudes = async () => {
      try {
        await store.dispatch('fetchMaraudes')
        // Programme les notifications pour chaque maraude
        if (store.state.notificationsEnabled) {
          console.log('Programming notifications for maraudes:', store.state.maraudes)
          store.state.maraudes.forEach(maraude => {
            const notificationTime = new Date(maraude.date)
            notificationTime.setMinutes(notificationTime.getMinutes() - 1)
            const now = new Date()
            const delay = notificationTime.getTime() - now.getTime()

            if (delay > 0) {
              console.log(`Scheduling notification for maraude ${maraude.title} in ${delay}ms`)
              setTimeout(() => {
                if (Notification.permission === 'granted' && store.state.notificationsEnabled) {
                  new Notification('Rappel Maraude', {
                    body: `La maraude "${maraude.title}" commence dans 1 minute !`,
                    icon: '/icon.png'
                  })
                }
              }, delay)
            }
          })
        }
      } catch (error) {
        console.error('Erreur lors du chargement des maraudes:', error)
      }
    }

    onMounted(() => {
      if (!user.value) {
        router.push('/login')
        return
      }
      // Vérifier si l'app est déjà installée
      const isInstalled = window.matchMedia('(display-mode: standalone)').matches
      if (!isInstalled) {
        // Ajouter l'écouteur d'événement pour l'installation
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
      }
      loadMaraudes()
    })
    onBeforeUnmount(() => {
      // Nettoyer l'écouteur d'événement
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    })

    return {
      showMenu,
      user,
      maraudes,
      loading,
      processingMaraude,
      formatDate,
      formatTime,
      maraudesByDay,
      isParticipating,
      participateInMaraude,
      cancelParticipation,
      handleLogout,
      loadMaraudes,
      openParticipantsModal,
      selectedMaraude,
      isCurrentUser,
      participantNames,
      router,
      showInstallBanner,
      installPWA
    }
  }
}
</script>
