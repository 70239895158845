<template>
  <div v-if="isReady">
    <router-view></router-view>
  </div>
  <div v-else class="min-h-screen flex items-center justify-center">
    <svg class="animate-spin h-10 w-10 text-primary-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const router = useRouter()
    const isReady = ref(false)

    onMounted(async () => {
      try {
        const isLoggedIn = await store.dispatch('checkSavedLogin')
        if (isLoggedIn) {
          await router.push('/maraudes')
        } else {
          await router.push('/')
        }
      } finally {
        isReady.value = true
      }
    })

    return {
      isReady
    }
  }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply m-0 p-0 h-full w-full;
}

#app {
  @apply font-sans antialiased h-full w-full;
}
</style>
