import { createStore } from 'vuex'
import Airtable from 'airtable'
import { format, parseISO } from 'date-fns'

const base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE_API_KEY })
                .base(process.env.VUE_APP_AIRTABLE_BASE_ID)

const formatPhoneNumber = (phoneNumber) => {
  // Supprime tous les caractères non numériques
  const numbers = phoneNumber.replace(/\D/g, '')
  
  // Format (061) 234-5678
  return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6)}`
}

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    maraudes: [],
    loading: false,
    notificationsEnabled: localStorage.getItem('notificationsEnabled') === 'true',
    users: {} // Cache pour stocker les informations des utilisateurs
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
    },
    SET_MARAUDES(state, maraudes) {
      state.maraudes = maraudes
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    UPDATE_MARAUDE_PARTICIPANTS(state, { maraudeId, participants }) {
      const maraudeIndex = state.maraudes.findIndex(m => m.id === maraudeId)
      if (maraudeIndex !== -1) {
        state.maraudes[maraudeIndex] = {
          ...state.maraudes[maraudeIndex],
          participants
        }
      }
    },
    SET_NOTIFICATIONS_ENABLED(state, enabled) {
      state.notificationsEnabled = enabled
      localStorage.setItem('notificationsEnabled', enabled)
    },
    SET_USER_INFO(state, { userId, userInfo }) {
      state.users[userId] = userInfo
    }
  },

  actions: {
    async checkUserExists({ commit }, phoneNumber) {
      try {
        const formattedPhone = formatPhoneNumber(phoneNumber)
        const records = await base('Bénévoles2025')
          .select({
            filterByFormula: `{Téléphone} = '${formattedPhone}'`
          })
          .firstPage()
        
        return records.length > 0
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'utilisateur:', error)
        throw error
      }
    },

    async createUser({ commit }, { phoneNumber, firstName }) {
      try {
        const formattedPhone = formatPhoneNumber(phoneNumber)
        const record = await base('Bénévoles2025').create({
          "Prénom": firstName,
          "Téléphone": formattedPhone
        })

        const userData = {
          id: record.id,
          firstName,
          phoneNumber: formattedPhone,
          participations: []
        }

        commit('SET_USER', userData)
        localStorage.setItem('userPhone', phoneNumber)
        return record
      } catch (error) {
        console.error('Erreur lors de la création de l\'utilisateur:', error)
        throw error
      }
    },

    async login({ commit }, phoneNumber) {
      try {
        const formattedPhone = formatPhoneNumber(phoneNumber)
        const records = await base('Bénévoles2025')
          .select({
            filterByFormula: `{Téléphone} = '${formattedPhone}'`
          })
          .firstPage()

        if (records.length === 0) {
          throw new Error('Utilisateur non trouvé')
        }

        const record = records[0]
        const userData = {
          id: record.id,
          firstName: record.fields['Prénom'],
          phoneNumber: record.fields['Téléphone'],
          participations: record.fields['Participation maraude'] || []
        }

        commit('SET_USER', userData)
        localStorage.setItem('userPhone', phoneNumber)
        return record
      } catch (error) {
        console.error('Erreur lors de la connexion:', error)
        throw error
      }
    },

    async logout({ commit }) {
      commit('SET_USER', null)
      commit('SET_MARAUDES', [])
      localStorage.removeItem('userPhone')
    },

    async checkSavedLogin({ dispatch }) {
      const savedPhone = localStorage.getItem('userPhone')
      if (savedPhone) {
        try {
          await dispatch('login', savedPhone)
          return true
        } catch (error) {
          console.error('Erreur lors de la reconnexion automatique:', error)
          localStorage.removeItem('userPhone')
          return false
        }
      }
      return false
    },

    async fetchMaraudes({ commit }) {
      try {
        commit('SET_LOADING', true)
        const now = new Date()
        const today = now.toISOString().split('T')[0]

        const response = await base('Maraudes2025').select({
          view: 'Grid view',
          filterByFormula: `IS_AFTER({Date}, '${today}')`,
          sort: [{ field: 'Date', direction: 'asc' }]
        }).all()
        
        const maraudes = response.map(record => ({
          id: record.id,
          title: record.fields.Titre,
          date: record.fields.Date,
          participants: record.fields.Participants || []
        }))
        
        commit('SET_MARAUDES', maraudes)
      } catch (error) {
        console.error('Erreur lors de la récupération des maraudes:', error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async participateInMaraude({ commit, dispatch, state }, maraudeId) {
      try {
        const maraude = state.maraudes.find(m => m.id === maraudeId)
        if (!maraude) return

        // Ajouter l'utilisateur à la liste des participants
        const updatedParticipants = [...maraude.participants, state.user.id]
        await base('Maraudes2025').update(maraudeId, {
          Participants: updatedParticipants
        })

        commit('UPDATE_MARAUDE_PARTICIPANTS', {
          maraudeId,
          participants: updatedParticipants
        })

        // Envoyer une notification immédiate de confirmation
        await dispatch('sendNotification', {
          title: 'Inscription confirmée',
          body: `Vous êtes inscrit(e) à la maraude du ${format(parseISO(maraude.date), 'dd/MM/yyyy')} à ${format(parseISO(maraude.date), 'hh:mm')}`,
          tag: `maraude-${maraudeId}-inscription`
        })

        // Planifier la notification pour le rappel
        dispatch('scheduleMaraudeNotification', maraude)

      } catch (error) {
        console.error('Erreur lors de la participation:', error)
        throw error
      }
    },

    async cancelParticipation({ commit, dispatch, state }, maraudeId) {
      try {
        const maraude = state.maraudes.find(m => m.id === maraudeId)
        if (!maraude) return

        // Retirer l'utilisateur de la liste des participants
        const updatedParticipants = maraude.participants.filter(p => p !== state.user.id)
        await base('Maraudes2025').update(maraudeId, {
          Participants: updatedParticipants
        })

        commit('UPDATE_MARAUDE_PARTICIPANTS', {
          maraudeId,
          participants: updatedParticipants
        })

        // Envoyer une notification immédiate de confirmation
        await dispatch('sendNotification', {
          title: 'Désinscription confirmée',
          body: `Vous vous êtes désinscrit(e) de la maraude du ${format(parseISO(maraude.date), 'dd/MM/yyyy')} à ${format(parseISO(maraude.date), 'hh:mm')}`,
          tag: `maraude-${maraudeId}-desinscription`
        })

      } catch (error) {
        console.error('Erreur lors de l\'annulation:', error)
        throw error
      }
    },

    async sendNotification({ state }, { title, body, tag }) {
      if (!state.notificationsEnabled) {
        console.log('Notifications désactivées')
        return
      }

      try {
        // Vérifier si le navigateur supporte les notifications
        if (!('Notification' in window) || !('serviceWorker' in navigator)) {
          console.log('Les notifications ne sont pas supportées')
          return
        }

        // Demander la permission si nécessaire
        let permission = Notification.permission
        if (permission === 'default') {
          permission = await Notification.requestPermission()
        }

        if (permission === 'granted') {
          try {
            // Enregistrer le service worker si ce n'est pas déjà fait
            const registration = await navigator.serviceWorker.register('/sw.js')
            console.log('Service Worker enregistré:', registration)

            // Envoyer la notification via le service worker
            await registration.showNotification(title, {
              body,
              tag,
              icon: '/favicon.ico',
              badge: '/favicon.ico',
              vibrate: [200, 100, 200],
              requireInteraction: true,
              data: {
                url: window.location.origin
              }
            })
            console.log('Notification envoyée avec succès')
          } catch (error) {
            console.error('Erreur lors de l\'envoi de la notification via SW:', error)
            // Fallback aux notifications standard si le service worker échoue
            new Notification(title, {
              body,
              tag,
              icon: '/favicon.ico'
            })
          }
        } else {
          console.log('Permission de notification refusée:', permission)
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi de la notification:', error)
        throw error
      }
    },

    async scheduleMaraudeNotification({ state, dispatch }, maraude) {
      if (!state.notificationsEnabled) return

      try {
        if (!('Notification' in window) || !('serviceWorker' in navigator)) {
          console.log('Les notifications ne sont pas supportées')
          return
        }

        const notificationTime = new Date(maraude.date)
        notificationTime.setMinutes(notificationTime.getMinutes() - 1)
        const now = new Date()
        const delay = notificationTime.getTime() - now.getTime()

        if (delay <= 0) return // Ne pas planifier si c'est dans le passé

        // Enregistrer la notification dans le localStorage
        const notifications = JSON.parse(localStorage.getItem('scheduledNotifications') || '[]')
        notifications.push({
          id: `maraude-${maraude.id}`,
          time: notificationTime.getTime(),
          title: 'Rappel Maraude',
          body: `La maraude à ${maraude.lieu} commence dans 1 minute !`,
          tag: `maraude-${maraude.id}-reminder`
        })
        localStorage.setItem('scheduledNotifications', JSON.stringify(notifications))

        // Vérifier et enregistrer le service worker si nécessaire
        const registration = await navigator.serviceWorker.register('/sw.js')
        
        // Planifier la notification via le service worker
        await registration.periodicSync.register('check-notifications', {
          minInterval: 60000 // Vérifier toutes les minutes
        })

      } catch (error) {
        console.error('Erreur lors de la planification de la notification:', error)
      }
    },

    async updateNotificationSettings({ commit }, enabled) {
      commit('SET_NOTIFICATIONS_ENABLED', enabled)
    },

    async fetchUserInfo({ commit, state }, userId) {
      // Si on a déjà l'information en cache, on ne refait pas la requête
      if (state.users[userId]) {
        return state.users[userId]
      }

      try {
        const record = await base('Users').find(userId)
        const userInfo = {
          id: record.id,
          firstName: record.fields.Prénom || 'Anonyme',
          phone: record.fields.Téléphone
        }
        commit('SET_USER_INFO', { userId, userInfo })
        return userInfo
      } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur:', error)
        return { firstName: 'Anonyme' }
      }
    }
  },

  getters: {
    isAuthenticated: state => !!state.user,
    userParticipations: state => state.user?.participations || []
  }
})
