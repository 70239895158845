<template>
  <div class="login-page">
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="relative min-h-screen flex items-center justify-center p-4">
      <div class="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-xl">
        <div>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Maraude App
          </h2>
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="handleLogin">
          <div>
            <label for="phone" class="sr-only">Numéro de téléphone</label>
            <input
              id="phone"
              v-model="phoneNumber"
              name="phone"
              type="tel"
              required
              class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
              placeholder="06XXXXXXXX"
              :class="{'border-red-500': phoneError}"
            />
            <p v-if="phoneError" class="mt-2 text-sm text-red-600">
              {{ phoneError }}
            </p>
          </div>

          <div>
            <button
              type="submit"
              :disabled="loading"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
            >
              <span v-if="loading" class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Loading spinner -->
                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Modal pour le prénom -->
    <div v-if="showFirstNameModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4 max-w-sm w-full">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
          Bienvenue !
        </h3>
        <form @submit.prevent="handleFirstNameSubmit">
          <div>
            <label for="firstName" class="block text-sm font-medium text-gray-700">
              Votre prénom
            </label>
            <input
              id="firstName"
              v-model="firstName"
              type="text"
              required
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div class="mt-5 sm:mt-6">
            <button
              type="submit"
              :disabled="savingFirstName"
              class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm disabled:opacity-50"
            >
              {{ savingFirstName ? 'Enregistrement...' : 'Continuer' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'LoginView',
  setup() {
    const router = useRouter()
    const store = useStore()
    const phoneNumber = ref('')
    const firstName = ref('')
    const loading = ref(false)
    const savingFirstName = ref(false)
    const showFirstNameModal = ref(false)
    const phoneError = ref('')

    const validatePhone = () => {
      if (!/^0[67][0-9]{8}$/.test(phoneNumber.value)) {
        phoneError.value = 'Numéro de téléphone invalide'
        return false
      }
      phoneError.value = ''
      return true
    }

    const handleLogin = async () => {
      if (!validatePhone()) return

      loading.value = true
      try {
        const userExists = await store.dispatch('checkUserExists', phoneNumber.value)
        if (!userExists) {
          showFirstNameModal.value = true
        } else {
          await store.dispatch('login', phoneNumber.value)
          router.push('/maraudes')
        }
      } catch (error) {
        console.error('Erreur de connexion:', error)
      } finally {
        loading.value = false
      }
    }

    const handleFirstNameSubmit = async () => {
      if (!firstName.value.trim()) return

      savingFirstName.value = true
      try {
        await store.dispatch('createUser', {
          phoneNumber: phoneNumber.value,
          firstName: firstName.value.trim()
        })
        showFirstNameModal.value = false
        router.push('/maraudes')
      } catch (error) {
        console.error('Erreur lors de la création du compte:', error)
      } finally {
        savingFirstName.value = false
      }
    }

    return {
      phoneNumber,
      firstName,
      loading,
      savingFirstName,
      showFirstNameModal,
      phoneError,
      handleLogin,
      handleFirstNameSubmit
    }
  }
}
</script>

<style scoped>
.login-page {
  @apply min-h-screen w-full bg-cover bg-center;
  background-image: url("https://www.leparisien.fr/resizer/0lUzYJC27L8bTqPd1Ja-Pf3PGcI=/932x582/cloudfront-eu-central-1.images.arcpublishing.com/leparisien/V2NB7O5R3LC566GWDCH4JG7OWY.jpg");
}
</style>
