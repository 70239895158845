<template>
  <div class="min-h-screen bg-gray-100">
    <!-- Header -->
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center">
        <button
          @click="router.push('/maraudes')"
          class="mr-4 text-gray-600 hover:text-gray-900"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        <h2 class="text-xl font-semibold text-gray-900">
          Paramètres
        </h2>
      </div>
    </header>

    <!-- Contenu -->
    <main class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="bg-white shadow rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg font-medium text-gray-900 mb-4">Notifications</h3>
          
          <div class="space-y-4">
            <!-- Activation des notifications -->
            <div class="flex items-center justify-between">
              <div>
                <p class="text-base font-medium text-gray-900">Activer les notifications</p>
                <p class="text-sm text-gray-500">Recevez une notification 1 minute avant chaque maraude</p>
              </div>
              <button
                @click="toggleNotifications"
                :class="[
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                  notificationsEnabled ? 'bg-primary-600' : 'bg-gray-200'
                ]"
              >
                <span
                  :class="[
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    notificationsEnabled ? 'translate-x-5' : 'translate-x-0'
                  ]"
                ></span>
              </button>
            </div>

            <!-- État des notifications -->
            <div v-if="notificationStatus" class="rounded-md bg-blue-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-blue-700">
                    {{ notificationStatus }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'Settings',
  setup() {
    const router = useRouter()
    const store = useStore()
    const notificationsEnabled = ref(false)
    const notificationStatus = ref('')

    const checkNotificationPermission = async () => {
      if (!('Notification' in window)) {
        notificationStatus.value = 'Votre navigateur ne supporte pas les notifications'
        return false
      }

      if (Notification.permission === 'granted') {
        notificationsEnabled.value = true
        return true
      }

      if (Notification.permission === 'denied') {
        notificationStatus.value = 'Les notifications ont été bloquées. Veuillez les autoriser dans les paramètres de votre navigateur.'
        return false
      }

      return null
    }

    const toggleNotifications = async () => {
      try {
        if (!('Notification' in window) || !('serviceWorker' in navigator)) {
          notificationStatus.value = 'Les notifications ne sont pas supportées sur votre appareil'
          return
        }

        if (notificationsEnabled.value) {
          await store.dispatch('updateNotificationSettings', false)
          notificationsEnabled.value = false
          notificationStatus.value = 'Notifications désactivées'
        } else {
          const permission = await Notification.requestPermission()
          if (permission === 'granted') {
            try {
              // Enregistrer le service worker
              const registration = await navigator.serviceWorker.register('/sw.js')
              console.log('Service Worker enregistré avec succès:', registration)
              
              await store.dispatch('updateNotificationSettings', true)
              notificationsEnabled.value = true
              notificationStatus.value = 'Notifications activées'
              
              // Envoyer une notification de test
              await testNotification()
            } catch (error) {
              console.error('Erreur lors de l\'enregistrement du Service Worker:', error)
              notificationStatus.value = 'Une erreur est survenue lors de l\'activation des notifications'
            }
          } else {
            notificationStatus.value = 'Permission refusée par le navigateur'
          }
        }
      } catch (error) {
        console.error('Erreur lors de la gestion des notifications:', error)
        notificationStatus.value = 'Une erreur est survenue lors de la gestion des notifications'
      }
    }

    const testNotification = async () => {
      await store.dispatch('sendNotification', {
        title: 'Test de notification',
        body: 'Si vous voyez cette notification, tout fonctionne correctement ! ',
        tag: 'test-notification'
      })
    }

    onMounted(async () => {
      const permissionStatus = await checkNotificationPermission()
      if (permissionStatus === true) {
        notificationsEnabled.value = store.state.notificationsEnabled
      }
    })

    return {
      router,
      notificationsEnabled,
      notificationStatus,
      toggleNotifications,
      testNotification
    }
  }
}
</script>
