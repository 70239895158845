import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'
import Maraudes from '../views/Maraudes.vue'
import Settings from '../views/Settings.vue'

const routes = [
  {
    path: '/',
    redirect: '/maraudes'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/maraudes',
    name: 'Maraudes',
    component: Maraudes,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/maraudes'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = store.state.user !== null

  // Si l'utilisateur est sur la page de login et est déjà authentifié
  if (to.path === '/login' && isAuthenticated) {
    next('/maraudes')
    return
  }

  // Si la route nécessite une authentification et l'utilisateur n'est pas authentifié
  if (requiresAuth && !isAuthenticated) {
    next('/login')
    return
  }

  next()
})

export default router
